<template>
  <el-dialog :visible.sync="dialogFormVisible" :show-close="false">
    <div slot="title" class="left_top">
      <div class="left_icon">
        <span></span>
        <span>{{ title }}</span>
      </div>
    </div>
    <el-form
      ref="form"
      :model="form"
      :rules="ruleForm"
      label-position="top"
      style="text-align:left"
    >
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="手机号 ( 用于找回密码 )" prop="phone">
        <el-input
          v-model="form.phone"
          type="text"
          placeholder="请输入手机号"
          readonly
          onfocus="this.removeAttribute('readonly');"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          v-model="form.password"
          type="password"
          placeholder="请输入密码"
          auto-complete="new-password"
          readonly
          onfocus="this.removeAttribute('readonly');"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align:center">
      <el-button type="primary" @click="submit('form')">确 定</el-button>
      <el-button @click="closeDialog('form')">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { phoneReg } from '../../lib/global';
import { api } from '../../api';
export const agentGroupAddAccount = api()('agent.group.add.account.json');
export const agentUserAddAccount = api()('agent.user.add.account.json');
export default {
  props: {
    dialogFormVisible: Boolean,
    type: String
  },
  watch: {
    type: function (val, old) {
      if (val === old) return;
      if (this.type === 'group') {
        this.title = '创建代理组';
      }
      if (this.type === 'user') {
        this.title = '创建代理用户';
      }
    }
  },
  data () {
    return {
      form: {
        name: '',
        phone: '',
        password: ''
      },
      ruleForm: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      title: ''
    };
  },

  methods: {
    closeDialog (form) {
      this.$refs[form].resetFields();
      this.$emit('update:dialogFormVisible', false);
      this.$emit('close');
    },
    submit (form) {
      this.$refs[form].validate(async isValid => {
        if (isValid) {
          if (!phoneReg.test(this.form.phone)) {
            return this.$message.error('请输入正确的手机号码！');
          }
          console.log(this.form, this.type);
          if (this.type === 'group') {
            await agentGroupAddAccount(this.form);
          }
          if (this.type === 'user') {
            await agentUserAddAccount(this.form);
          }
          this.$message.success('创建成功！');
          this.closeDialog(form);
        }
      });
    }
  }
};
</script>
